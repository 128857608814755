import { Router } from 'vue-router';

import { useAuth } from '@/features/Auth';

import { AppRoutes } from './data';

export const applyGuards = (router: Router) => {
  router.beforeEach((to, from, next) => {
    if (to.path === from.path && !from.name) return;

    const { account, loginUrl } = useAuth();

    if (to.meta.protected && account.value === null) {
      window.location.replace(loginUrl);
    } else if (!to.meta.protected && account.value !== null) {
      next({ name: AppRoutes.invoices.name });
    } else next();
  });
};

export const handleError = (router: Router) => {
  router.onError((error, to) => {
    if (
      error.message.includes('Failed to fetch dynamically imported module') ||
      error.message.includes('Importing a module script failed')
    ) {
      window.location.href = to.fullPath;
    }
  });
};
