import { AfterResponseHook, BeforeRequestHook, BeforeRetryHook } from 'ky';

import { ResponseError } from '@/api';
import { LOCALE } from '@/config';
import { useAuth, useAuthMethods } from '@/features/Auth';
import { AppEvents } from '@/features/Common';
import { showErrorModalCodes } from '@/features/Modals/error/composables';
import { notification } from '@/features/Notifications';
import { i18n } from '@/locales';

import { IAuthAccountDto } from './auth';

let refreshTokenPromise: Promise<IAuthAccountDto | void> | null;

declare global {
  interface RequestInit {
    afterResponseHookOptions?: {
      handleBadResponse?:
        | boolean
        | ((options: {
            request: Request;
            response: Response;
            responseError?: ResponseError;
          }) => boolean);
    };
  }
}

export const beforeRequestHook: BeforeRequestHook = (request) => {
  const { token } = useAuth();

  request.headers.set('Authorization', `Bearer ${token}`);
  request.headers.set('Accept-Language', LOCALE || 'ru-RU');
};

export const beforeRetryHook: BeforeRetryHook = async ({ request }) => {
  try {
    const auth = await refreshTokenPromise;

    if (auth) {
      request.headers.set('Authorization', `Bearer ${auth.accessToken}`);
      request.headers.set('Accept-Language', LOCALE || 'ru-RU');
    }
  } catch (e) {
    const { logOut } = useAuthMethods();

    logOut();
    throw e;
  }
};

export const afterResponseHook: AfterResponseHook = async (
  input,
  options,
  response,
) => {
  const { refreshToken } = useAuthMethods();

  if (response.status === 401) {
    if (refreshTokenPromise) return response;

    refreshTokenPromise = refreshToken().then((auth) => {
      refreshTokenPromise = null;

      return auth;
    });

    return response;
  }

  if (response.status === 403) {
    eventManager.emit(AppEvents.pages.error.open, 403);

    return response;
  }

  if (response.status >= 400 && response.status <= 599) {
    const res = await response.json();
    const { code, detail } = res;
    const errCode = `modal.error.codes.${code}`;

    const shouldOpenModal =
      code &&
      ![
        1000,
        1017,
        1018,
        1003,
        1016,
        'DUPLICATE_USER_WITH_THE_PHONE_OR_EMAIL',
        ...showErrorModalCodes,
      ].includes(code) &&
      !(code === 1002 && Array.isArray(detail));

    const { handleBadResponse = true } = options.afterResponseHookOptions ?? {};

    const shouldHandleBadResponse =
      typeof handleBadResponse === 'function'
        ? handleBadResponse({ request: input, response, responseError: res })
        : handleBadResponse;

    if ([500, 1001].includes(res?.code)) {
      return response;
    } else if (shouldHandleBadResponse && shouldOpenModal) {
      notification.error(i18n.global.t(errCode), {
        key: errCode,
        description:
          code === 1031 ? i18n.global.t(`modal.error.description.1031`) : '',
      });
    }

    return res;
  }

  return response;
};
